import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, FormGroup, InputGroup, Intent, Alert, MenuItem } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import { createOrganization, deleteOrganization, switchOrg } from '../actions/organizations'
import * as PropTypes from 'prop-types'
import config from '../config'
import './configOrgTab.css'

export default function ConfigOrgTab () {
  return (
    <div>
      <OrgSwitcherPanel />
      <CreateOrgPanel />
      <DeleteOrgPanel />
    </div>
  )
}

function SettingsPanel (props) {
  return (
    <div className='settings-panel'>
      <Divider style={{ marginTop: '2rem', marginBottom: '2rem' }} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='settings-panel-text' style={{ marginRight: '2rem', paddingTop: '0.5rem' }}>
          <h3 className='bp3-heading'>{props.title}</h3>
          <div style={{ maxWidth: '400px' }}>{props.text}</div>
        </div>
        {props.children}
      </div>
    </div>
  )
}

SettingsPanel.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  children: PropTypes.node
}

function OrgSwitcherPanel () {
  const orgs = useSelector(state => state.organizations)
  const dispatch = useDispatch()

  const OrgChange = (org) => {
    dispatch(switchOrg(org))
    localStorage.setItem(config.localStorage.currentOrganization, JSON.stringify(org))
  }

  const [activeItem, setActiveItem] = useState('')
  const OrgSuggestRenderer = (org, itemProps) => {
    const OnClick = () => {
      OrgChange(org)
    }

    if (!org.metadata.name.includes(itemProps.query)) {
      return null
    }
    return (
      <div key={itemProps.index}>
        <MenuItem text={org.metadata.name} onClick={OnClick} active={itemProps.modifiers.active} />
      </div>
    )
  }

  return (
    <SettingsPanel
      title='Switch Organization'
      text='Choose the organization that you are signed with.'
    >
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '38px' }}>
        <FormGroup
          labelFor='text-input'
          label='Choose Organization'
        >
          <Suggest
            popoverProps={{ minimal: true }}
            items={orgs.items}
            inputValueRenderer={(org) => { return org.metadata.name }}
            itemRenderer={OrgSuggestRenderer}
            selectedItem={orgs.selectedOrg}
            onItemSelect={() => { OrgChange(activeItem) }}
            onActiveItemChange={(activeItem) => setActiveItem(activeItem)}
          />
        </FormGroup>
      </div>
    </SettingsPanel>
  )
}

const isValidOrgName = (orgName) => {
  if (!orgName) {
    return true
  }
  return /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/.test(orgName)
}

function CreateOrgPanel () {
  const dispatch = useDispatch()
  const [newOrgName, setNewOrgName] = useState('')

  const WatchOrgText = (event) => {
    setNewOrgName(event.target.value)
  }

  const CreateOrgClick = () => {
    dispatch(createOrganization(newOrgName))
  }

  return (
    <SettingsPanel
      title='New Organization'
      text='An Organization is where you manage your clusters, workloads, and access to monitoring and logging.'
    >
      <div>
        <FormGroup label='Organization name' style={{ paddingTop: '1rem' }}>
          <InputGroup
            placeholder='My awesome org'
            onChange={WatchOrgText}
          />
        </FormGroup>
        <Button intent={isValidOrgName(newOrgName) ? Intent.SUCCESS : Intent.NONE} disabled={!isValidOrgName(newOrgName)} text='Create Organization' onClick={CreateOrgClick} />
      </div>
    </SettingsPanel>
  )
}

function DeleteOrgPanel () {
  const dispatch = useDispatch()
  const [deleteOrgAlert, setDeleteOrgAlert] = useState(false)
  const selectedOrg = useSelector(state => state.organizations.selectedOrg)

  const DeleteOrgConfirmationClick = () => {
    setDeleteOrgAlert(true)
  }

  const ConfirmDeleteOrgClick = () => {
    setDeleteOrgAlert(true)
    dispatch(deleteOrganization(selectedOrg.metadata.organization))
  }

  return (
    <SettingsPanel
      title='Delete Organization'
      text='Deleting an Organization will delete all of its related resources including clusters, workloads, etc.'
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Alert
          isOpen={deleteOrgAlert}
          onClose={() => setDeleteOrgAlert(false)}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          confirmButtonText='Confirm'
          style={{ display: 'flex' }}
          onConfirm={ConfirmDeleteOrgClick}
        >
          <h4 className='bp3-heading'>Confirmation Required</h4>
          <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
          <div style={{ color: 'red', marginBottom: '1rem' }}>You are going to remove {selectedOrg.metadata.name}.</div>
          <span>This action can lead to data loss. To prevent accidental actions we ask to confirm your intention. Press confirm to delete the organization or close this modal to cancel.</span>
        </Alert>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='bp3-text-muted' style={{ fontSize: '14px', paddingBottom: '0.5rem' }}>Organization: {selectedOrg.metadata.name}</div>
          <div>
            <Button intent={Intent.DANGER} text='Delete Organization' onClick={DeleteOrgConfirmationClick} />
          </div>
        </div>
      </div>
    </SettingsPanel>
  )
}
