import {
  REQUEST_ALL_ORGANIZATIONS,
  RECEIVE_ALL_ORGANIZATIONS,
  ERROR_ALL_ORGANIZATIONS,
  SWITCH_ORGANIZATION,
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION
} from '../actions/organizations'
import config from '../config'

const initialState = () => {
  const initial = {
    isFetching: false,
    error: null,
    didInvalidate: false,
    selectedOrg: { metadata: { name: 'None' } },
    items: []
  }

  const selectedOrg = localStorage.getItem(config.localStorage.currentOrganization)
  if (selectedOrg) {
    initial.selectedOrg = JSON.parse(selectedOrg)
  }

  return initial
}

export default function organizations (state = initialState(), action) {
  switch (action.type) {
    case REQUEST_ALL_ORGANIZATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        error: null
      })
    case RECEIVE_ALL_ORGANIZATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        error: null,
        items: action.items
      })
    case ERROR_ALL_ORGANIZATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case SWITCH_ORGANIZATION:
      return Object.assign({}, state, {
        selectedOrg: action.selectedOrg
      })
    case ADD_ORGANIZATION:
      state.items.unshift(action.newOrg)
      return Object.assign({}, state, {
        items: state.items
      })
    case DELETE_ORGANIZATION:
      return Object.assign({}, state, {
        items: state.items.filter(org => org.metadata.organization !== action.orgId)
      })
    default:
      return state
  }
}
