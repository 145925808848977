import React from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import MainPage from '../components/mainPage'
import { Tab, Tabs, Icon, Callout, Intent, Divider } from '@blueprintjs/core'
import ConfigOrgTab from '../components/configOrgTab'
import { leftNavigationWidth } from '../components/leftNavigation'
import './configurationPage.css'
import { Uppercase } from '../util'

const currentTabs = [
  {
    id: 'organizations',
    panel: <ConfigOrgTab />
  },
  {
    id: 'account',
    panel: <UnderConstruction />
  },
  {
    id: 'preferences',
    panel: <UnderConstruction />
  }
]

function ConfigurationPage () {
  const { tab } = useParams()
  const history = useHistory()

  const handleTabChange = (newTab) => {
    history.push(`/configuration/${newTab}`)
  }

  const VerifyTab = () => {
    const tabExists = currentTabs.find(e => e.id === tab)
    if (!tabExists) {
      return (
        <Redirect to='/notfound' />
      )
    }
    return null
  }

  return (
    <MainPage>
      <VerifyTab />
      <div className='config-page' style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', marginRight: `${leftNavigationWidth / 2}px` }}>
        <div className='config-header'>
          <div className='config-header-icon-box' style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <Icon icon='cog' iconSize={56} color='#5C7080' />
            <div className='bp3-ui-text bp3-text-muted' style={{ fontSize: '26px', paddingLeft: '12px' }}>User Settings</div>
          </div>
        </div>
        <div className='tab-wrapper' style={{ display: 'flex', flexGrow: 11 }}>
          <Tabs id='TabsExample' onChange={handleTabChange} selectedTabId={tab} renderActiveTabPanelOnly large>
            {currentTabs.map(e => { return <Tab key={e.id} id={e.id} title={Uppercase(e.id)} panel={e.panel} /> })}
          </Tabs>
        </div>
      </div>
    </MainPage>)
}

export default ConfigurationPage

function UnderConstruction () {
  return (
    <div>
      <Divider style={{ marginBottom: '2rem', marginTop: '2rem' }} />
      <Callout intent={Intent.WARNING} title='Area under construction' style={{ maxWidth: '600px' }}>
        <div>
          <span>EADP Registry is still a work in-progress, more features and configuration will be made available as development continues. For questions about Registry, reach out to </span>
          <a href='https://electronic-arts.slack.com/app_redirect?channel=eadp-cloud' target='_blank' rel='noopener noreferrer'>#eadp-cloud</a>
          <span> on slack</span>
        </div>
      </Callout>
    </div>
  )
}
