import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

export const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
