import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import config from '../config'
import { useSelector, useDispatch } from 'react-redux'
import { authCallback } from '../actions/auth'
import LoadingSpinner from '../components/loadingSpinner'
import * as PropTypes from 'prop-types'

/* AuthRoute wraps a react component with a check for valid Registry credentials
*  If credentials are not found or expired, the  user is redirect to the OAuthLogin */
const AuthRoute = React.memo(({ children, ...rest }) => {
  const authStore = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!authStore.isAuthenticated) {
      if (config.debug) console.log('Token expiration not found or expired')
      // Attempt token refresh if possible
      const refreshToken = localStorage.getItem(config.localStorage.oauthRefreshToken)
      if (refreshToken !== null && refreshToken !== '') {
        if (config.debug) console.log('Refresh token found - attempting refresh')
        dispatch(authCallback('refresh_token', refreshToken, true))
      }
    }
  }, [dispatch, authStore.isAuthenticated])

  if (authStore.isAuthenticated) {
    if (config.debug) console.log('Authenticated')
    return (
      <Route {...rest}>
        {children}
      </Route>
    )
  }
  if (authStore.isFetching || authStore.isRefresh) {
    return (
      <LoadingSpinner text='Refreshing login to Registry' />
    )
  }
  if (authStore.error) {
    return (
      <div>Unauthenticated: {authStore.error} </div>
    )
  }

  if (config.debug) console.log('Not Authenticated')
  if (config.debug) console.log('Setting oauth redirect to ', rest.path)
  localStorage.setItem(config.localStorage.oauthRedirectPage, rest.path)
  return (
    <Redirect to={{ pathname: '/oauth2/login' }} />
  )
})

export {
  AuthRoute
}

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired
}
