import React from 'react'
import { Tree } from '@blueprintjs/core'
import { Column } from './mainStyles'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectNavigation } from '../actions/leftNavigation'
import './leftNavigation.css'

const leftNavigationColor = '#EBF1F5'
export const leftNavigationWidth = 220
const LeftNavigationStyle = {
  width: `${leftNavigationWidth}px`,
  flexShrink: 0,
  margin: '0 12px 0 0',
  padding: '12px 0 0 0',
  backgroundColor: leftNavigationColor
}

export default function LeftNavigation () {
  const history = useHistory()
  const leftNavigationMenu = useSelector(state => state.leftNavigation)
  const dispatch = useDispatch()

  const onNodeClick = (node) => {
    if (node.childNodes !== undefined && node.childNodes.length !== 0) {
      dispatch(selectNavigation(node.id))
    } else {
      history.push(node.path)
    }
  }

  return (
    <Column className='left-navigation' style={LeftNavigationStyle}>
      <Tree
        onNodeClick={onNodeClick} style={{ ':hover': { backgroundColor: 'red' } }}
        contents={leftNavigationMenu}
      />
    </Column>
  )
}
