import React from 'react'
import { Column } from '../components/mainStyles'
import MainPage from '../components/mainPage'

export default function HomePage (props) {
  const Home = () => {
    return (
      <Column />
    )
  }

  return <MainPage component={Home} props={props} />
}
