import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Column, Row } from '../components/mainStyles'
import { Divider, Spinner, Intent, Toast, ControlGroup, InputGroup } from '@blueprintjs/core'
import * as PropTypes from 'prop-types'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import { fetchOrganizations, fetchResource } from '../actions/resourcePagination'
import './resourceOverview.css'

const NoDataIndication = (props) => {
  if (props.loading) {
    return <Spinner intent={Intent.PRIMARY} />
  }
  return <div />
}

NoDataIndication.propTypes = {
  loading: PropTypes.bool
}

const FetchError = (props) => {
  const [dismiss, setDismiss] = useState(false)
  if (!props.errorMessage) {
    return null
  }
  if (dismiss) {
    return null
  }
  return (
    <Toast
      intent={Intent.DANGER}
      message={`Error fetching clusters from Registry: ${props.errorMessage}`}
      className='error-toast'
      icon='error'
      onDismiss={() => { setDismiss(true) }}
      timeout={0}
    />)
}

FetchError.propTypes = {
  errorMessage: PropTypes.string
}

const sizePerPage = 25

export default function ResourceOverviewPage (props) {
  const resource = useSelector(state => state.resourcePagination)
  const selectedOrg = useSelector(state => state.organizations.selectedOrg)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.resource === 'organizations') {
      dispatch(fetchOrganizations(props.resource, selectedOrg.metadata.organization, sizePerPage * page))
    } else if (selectedOrg) {
      dispatch(fetchResource(props.resource, selectedOrg.metadata.organization, sizePerPage * page))
    }
  }, [dispatch, selectedOrg, props.resource, page])

  const handleTableChange = (type, newState) => {
    setPage(newState.page)
  }

  const mutateData = (elements) => {
    if (typeof props.dataMutator === 'function') {
      return props.dataMutator(elements)
    } else {
      return elements
    }
  }

  const pageOptions = {
    custom: true,
    totalSize: resource.total,
    page: page,
    hideSizePerPage: true,
    withFirstAndLast: false,
    paginationSize: 1,
    sizePerPage: sizePerPage
  }
  const lastElement = page * sizePerPage > pageOptions.totalSize ? pageOptions.totalSize : page * sizePerPage
  const initialElement = (page * sizePerPage) - (sizePerPage - 1)
  return (
    <Column style={{ paddingLeft: '24px', paddingTop: '12px', flexGrow: '1' }}>
      <FetchError errorMessage={resource.error} />
      <Row style={{ alignItems: 'center' }}>
        <h3 className='bp3-heading'> {props.resource.charAt(0).toUpperCase() + props.resource.slice(1)}</h3>
        <Divider style={{ height: '25px', marginBottom: '8px' }} />
        <h5 className='bp3-heading bp3-text-disabled'> Registry Resources</h5>
      </Row>
      <PaginationProvider pagination={paginationFactory(pageOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <div className='table-top-bar'>
              <div className='table-search-bar'>
                <ControlGroup>
                  <InputGroup placeholder='Search' leftIcon='search' disabled />
                </ControlGroup>
              </div>
              <div className='table-page-controls'>
                <div className='table-page-stats'>{initialElement}-{lastElement} of {pageOptions.totalSize}</div>
                <PaginationListStandalone
                  {...paginationProps}
                />
              </div>
            </div>
            <BootstrapTable
              keyField='metadata.uid'
              data={mutateData(resource.items.slice(initialElement - 1, lastElement))}
              columns={props.columns}
              hover
              condensed
              classes='overview-table'
              remote
              noDataIndication={() => <NoDataIndication loading={resource.isFetching} />}
              onTableChange={handleTableChange}
              {...paginationTableProps}
            />
          </div>)}
      </PaginationProvider>
    </Column>
  )
}

ResourceOverviewPage.propTypes = {
  resource: PropTypes.string,
  columns: PropTypes.array,
  dataMutator: PropTypes.func
}
