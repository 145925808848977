import config from '../config'
export const REQUEST_PERMISSIONS = 'requestPermissions'
function requestPermissions () {
  return {
    type: REQUEST_PERMISSIONS
  }
}

export const RECEIVE_PERMISSIONS = 'receivePermissions'
function receivePermissions (readAll, writeAll) {
  return {
    type: RECEIVE_PERMISSIONS,
    readAll: readAll,
    writeAll: writeAll
  }
}

export function fetchPermissions () {
  return function (dispatch, getState) {
    dispatch(requestPermissions())

    const authToken = getState().auth.token
    return fetch(`${config.registryAPI}/v1/organizations/0`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(
      response => {
        if (response.status === 200) {
          dispatch(receivePermissions(true, false))
        } else {
          dispatch(receivePermissions(false, false))
        }
      },
      error => {
        console.log('An error occurred.', error)
      }
    )
  }
}
