import {
  REQUEST_CLUSTERS,
  RECEIVE_CLUSTERS,
  ERROR_CLUSTERS
} from '../actions/clusters'

const initialState = {
  isFetching: false,
  error: null,
  didInvalidate: false,
  items: []
}

export default function clusters (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CLUSTERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        error: null
      })
    case RECEIVE_CLUSTERS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.clusters,
        lastUpdated: action.receivedAt,
        error: null
      })
    case ERROR_CLUSTERS:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    default:
      return state
  }
}
