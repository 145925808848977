import { combineReducers } from 'redux'
import leftNavigation from './leftNavigation'
import clusters from './cluster'
import auth from './auth'
import permissions from './permissions'
import organizations from './organizations'
import resourcePagination from './resourcePagination'

export default combineReducers({
  leftNavigation,
  clusters,
  auth,
  permissions,
  organizations,
  resourcePagination
})
