import {
  REQUEST_AUTH,
  RECEIVE_AUTH,
  AUTH_ERROR
} from '../actions/auth'
import config from '../config'
import decode from 'jwt-decode'
import { isAuthenticated } from '../util/oauth'

const initialState = () => {
  const initial = {
    isFetching: false,
    isRefresh: false,
    error: null,
    isAuthenticated: false,
    token: null,
    name: null,
    expiry: null
  }
  const token = localStorage.getItem(config.localStorage.oauthIDToken)
  if (token !== null) {
    const decoded = decode(token)
    initial.token = token
    initial.name = decoded[config.oauth.usernameClaim]
    initial.expiry = decoded.exp
    initial.isAuthenticated = isAuthenticated(decoded.exp)
  }
  return initial
}

export default function auth (state = initialState(), action) {
  const currentAuthentication = isAuthenticated(state.expiry)
  switch (action.type) {
    case AUTH_ERROR:
      sessionStorage.removeItem(config.localStorage.stateUuid)
      localStorage.removeItem(config.localStorage.oauthIDToken)
      localStorage.removeItem(config.localStorage.oauthRefreshToken)
      return Object.assign({}, state, {
        error: action.error,
        isAuthenticated: false,
        isFetching: false,
        isRefresh: false
      })
    case REQUEST_AUTH:
      return Object.assign({}, state, {
        isFetching: true,
        isRefresh: action.isRefresh
      })
    case RECEIVE_AUTH:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        token: action.token,
        name: action.name,
        expiry: action.expiry
      })
    default:
      if (currentAuthentication !== state.isAuthenticated) {
        return Object.assign({}, state, {
          isAuthenticated: currentAuthentication
        })
      } else {
        return state
      }
  }
}
