import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Navbar, Icon, Button, Popover, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { fetchAllOrganizations } from '../actions/organizations'
import ea from '../assets/ea-logo-white.png'
import './navBar.css'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as PropTypes from 'prop-types'

const NavBarStyle = {
  backgroundColor: '#0E5A8A',
  height: '60px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const RegistryHeaderStyle = {
  paddingLeft: '8px',
  margin: '0',
  color: 'white'
}

export default function NavBar () {
  const email = useSelector(state => state.auth.name)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllOrganizations())
  }, [dispatch])

  return (
    <Navbar style={NavBarStyle}>
      <div className='header' style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
        <div>
          <Link to='/home'>
            <img src={ea} alt='ea' height='45' width='90' />
          </Link>
        </div>
        <div>
          <h3 className='bp3-heading' style={RegistryHeaderStyle}>Registry</h3>
        </div>
        <OrganizationButton style={{ marginLeft: '20px' }} />
      </div>
      <AccountButton email={email} />
    </Navbar>
  )
}

function AccountButton (props) {
  const history = useHistory()

  let name = ''
  if (props.email) {
    name = props.email.substring(0, props.email.lastIndexOf('@'))
  }
  return (
    <div className='account-info' style={{ display: 'flex', alignItems: 'center' }}>
      <Popover
        minimal content={
          <Menu style={{ backgroundColor: '#EBF1F5', padding: '8px 4px 8px 4px' }}>
            <MenuDivider title={
              <div>
                <div className='org-heading-name bp3-text-muted'>  {props.email} </div>
              </div>
            }
            />
            <Menu.Divider className='account-button-divider' />
            <MenuItem text='Settings' onClick={() => history.push('/configuration/account')} />
            <Menu.Divider className='account-button-divider' />
            <MenuItem text='Sign out' onClick={() => history.push('/logout')} />
          </Menu>
        }
      >
        <Button
          className='account-button clear-button'
          icon={<Icon icon='user' iconSize={32} />}
          minimal text={name} large fill={false} style={{ color: '#D8E1E8' }}
          rightIcon='caret-down'
        />
      </Popover>
    </div>
  )
}

AccountButton.propTypes = {
  email: PropTypes.string.isRequired
}

function OrganizationButton (props) {
  const history = useHistory()
  const selectedOrg = useSelector(state => state.organizations.selectedOrg)

  return (
    <div style={props.style}>
      <Popover
        minimal content={
          <Menu style={{ backgroundColor: '#EBF1F5', padding: '8px 4px 8px 4px' }}>
            <MenuDivider title={
              <div>
                <div className='org-heading-name bp3-text-muted'>Current Organization</div>
                <div className='org-heading-name bp3-text-muted' style={{ paddingTop: '2px' }}>{selectedOrg.metadata.name}</div>
              </div>
            }
            />
            <Menu.Divider className='account-button-divider' />
            <MenuItem text='Switch Organization' onClick={() => history.push('/configuration/organizations')} />
          </Menu>
        }
      >
        <Button
          className='org-button clear-button'
          icon={<FontAwesomeIcon icon={faProjectDiagram} style={{ marginRight: '8px' }} />}
          minimal text={selectedOrg.metadata.name} large fill={false} style={{ color: '#D8E1E8' }}
          rightIcon='caret-down'
        />
      </Popover>
    </div>
  )
}

OrganizationButton.propTypes = {
  style: PropTypes.object
}
