import { token } from '../util/oauth'
import decode from 'jwt-decode'
import config from '../config'

export const AUTH_ERROR = 'authError'
export function authError (error) {
  return {
    type: AUTH_ERROR,
    error
  }
}

export const REQUEST_AUTH = 'requestAuth'
function requestAuth () {
  return {
    type: REQUEST_AUTH,
    isRefresh: false
  }
}

export const RECEIVE_AUTH = 'receiveAuth'
function receiveAuth (response) {
  const token = response.id_token
  const decoded = decode(token)
  localStorage.setItem(config.localStorage.oauthIDToken, token)
  if (response.refresh_token) {
    localStorage.setItem(config.localStorage.oauthRefreshToken, response.refresh_token)
  }
  return {
    type: RECEIVE_AUTH,
    token: token,
    name: decoded.email,
    expiry: decoded.exp
  }
}

export function authCallback (grant, code, isRefresh = false) {
  return function (dispatch) {
    dispatch(requestAuth(isRefresh))
    return token(grant, code).then(response => {
      dispatch(receiveAuth(response))
    },
    error => {
      console.log('An error occurred.', error)
      dispatch(authError(error))
    }
    )
  }
}
