import config from '../config'
export const REQUEST_ALL_ORGANIZATIONS = 'requestAllOrgs'
function requestAllOrgs () {
  return {
    type: REQUEST_ALL_ORGANIZATIONS
  }
}

export const RECEIVE_ALL_ORGANIZATIONS = 'receiveAllOrgs'
function receiveAllOrgs (json) {
  return {
    type: RECEIVE_ALL_ORGANIZATIONS,
    items: json.items
  }
}

export const ERROR_ALL_ORGANIZATIONS = 'errorAllOrgs'
function errorAllOrgs (error) {
  let errMsg = error
  if (error && error.kind && error.kind === 'Status') {
    errMsg = error.reason
  }
  return {
    type: ERROR_ALL_ORGANIZATIONS,
    error: errMsg
  }
}

export const SWITCH_ORGANIZATION = 'switchOrganization'
export function switchOrg (org) {
  return {
    type: SWITCH_ORGANIZATION,
    selectedOrg: org
  }
}

export const ADD_ORGANIZATION = 'addOrganization'
export function addOrg (newOrg) {
  return {
    type: ADD_ORGANIZATION,
    newOrg: newOrg
  }
}

export const DELETE_ORGANIZATION = 'deleteOrganization'
export function deleteOrg (orgId) {
  return {
    type: DELETE_ORGANIZATION,
    orgId: orgId
  }
}

export function fetchAllOrganizations () {
  return function (dispatch, getState) {
    dispatch(requestAllOrgs())

    const authToken = getState().auth.token
    return fetch(`${config.registryAPI}/v1/organizations`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(
      response => {
        if (response.status === 200) {
          response.json().then(json => dispatch(receiveAllOrgs(json)))
        } else {
          response.json().then(json => dispatch(errorAllOrgs(json)))
        }
      },
      error => {
        console.log('An error occurred.', error)
        dispatch(errorAllOrgs(error))
      }
    )
  }
}

export function createOrganization (orgName) {
  return function (dispatch, getState) {
    const authToken = getState().auth.token
    return fetch(`${config.registryAPI}/v1/organizations`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      method: 'POST',
      body: JSON.stringify({ metadata: { name: orgName } })
    }).then(
      response => {
        if (response.ok) {
          response.json().then(json => dispatch(addOrg(json)))
        } else {
          response.json().then(json => dispatch(errorAllOrgs(json)))
        }
      },
      error => {
        dispatch(errorAllOrgs(error))
      }
    )
  }
}

export function deleteOrganization (orgId) {
  return function (dispatch, getState) {
    const authToken = getState().auth.token
    return fetch(`${config.registryAPI}/v1/organizations/${orgId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      method: 'DELETE'
    }).then(
      response => {
        if (response.ok) {
          response.json().then(() => {
            dispatch(deleteOrg(orgId))
            dispatch(switchOrg(getState().organizations.items[0]))
          })
        } else {
          response.json().then(json => dispatch(errorAllOrgs(json)))
        }
      },
      error => {
        dispatch(errorAllOrgs(error))
      }
    )
  }
}
