import { Intent, Spinner } from '@blueprintjs/core'
import React from 'react'
import * as PropTypes from 'prop-types'

export default function LoadingSpinner (props) {
  return (
    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', flexDirection: 'column' }}>
      <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_LARGE} />
      <div className='bp3-text-large' style={{ display: 'flex', paddingTop: '10px', justifyContent: 'center' }}>{props.text}</div>
    </div>
  )
}

LoadingSpinner.propTypes = {
  text: PropTypes.node
}
