import config from '../config'

// borrowed from https://stackoverflow.com/a/2117523
function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

/* oauthAuthorize redirects the caller to the OAuth Authorize endpoint */
function oauthAuthorize (scope, state) {
  const baseEndpoint = config.oauth.authorizeEndpoint
  const clientId = config.oauth.clientID
  const redirectURI = `${window.location.origin}/oauth2/callback`
  window.location.replace(`${baseEndpoint}?response_type=code&client_id=${clientId}&state=${state}&redirect_uri=${redirectURI}&scope=${scope}`)
  return state
}

/* oauthToken takes the grant and code and makes the call to the OAuth token
*  endpoint, returning a promise with results/error */
function oauthToken (grant, code) {
  return new Promise(function (resolve, reject) {
    let codeInfo
    if (grant === 'authorization_code') {
      codeInfo = `code=${code}`
    } else if (grant === 'refresh_token') {
      codeInfo = `refresh_token=${code}`
    } else {
      reject(Error('invalid grant type'))
    }

    const redirectURI = `${window.location.origin}/oauth2/callback`
    const tokenOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `grant_type=${grant}&client_id=${config.oauth.clientID}&redirect_uri=${redirectURI}&${codeInfo}`
    }

    if (config.oauth.clientSecret !== '') {
      tokenOptions.headers.Authorization = btoa(`${config.oauth.clientID}:${config.oauth.clientSecret}`)
    }

    fetch(config.oauth.tokenEndpoint, tokenOptions).then(res => {
      if (res.status !== 200) {
        res.json().then(body => {
          reject(body)
        }
        )
        return
      }
      res.json().then(result => {
        resolve(result)
      },
      error => {
        reject(error)
      }
      )
    })
  })
}

export {
  oauthToken as token,
  oauthAuthorize as authorize,
  uuidv4
}

export function isAuthenticated (expiry) {
  if (expiry === undefined || expiry === null) {
    return false
  }
  const now = new Date()
  const expiryDate = new Date(expiry * 1000)
  return expiryDate > now
}
