import React from 'react'
import MainPage from '../components/mainPage'

function ErrorPage (errorMessage) {
  return (
    <MainPage>
      <div className='not-found-page' style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
        {errorMessage}
      </div>
    </MainPage>)
}

export default ErrorPage
