import {
  REQUEST_RESOURCE,
  RECEIVE_RESOURCE,
  ERROR_RESOURCE
} from '../actions/resourcePagination'

const initialState = {
  isFetching: false,
  error: null,
  didInvalidate: false,
  resourceType: null,
  org: null,
  nextElements: null,
  items: [],
  orgIndex: 0,
  continueToken: '',
  total: null
}

export default function resourcePagination (state = initialState, action) {
  let items = []
  switch (action.type) {
    case REQUEST_RESOURCE:
      if (state.resourceType === action.resourceType && state.org === action.org) {
        items = action.items
      }
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        error: null,
        nextElements: action.nextElements,
        resourceType: action.resourceType,
        org: action.org,
        items: items,
        total: action.total
      })
    case RECEIVE_RESOURCE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        error: null,
        items: action.items,
        continueToken: action.continueToken,
        orgIndex: action.orgIndex,
        total: action.total
      })
    case ERROR_RESOURCE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    default:
      return state
  }
}
