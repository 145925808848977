import { KubeIcon, Server, CogsIcon, SiteMapIcon } from '../components/customIcons'
import {
  SELECT_NAVIGATION
} from '../actions/leftNavigation'

const initialState = [
  {
    id: 0,
    hasCaret: false,
    icon: 'list-detail-view',
    label: 'Resources',
    isExpanded: true,
    childNodes: [
      {
        id: 1,
        hasCaret: false,
        icon: SiteMapIcon,
        label: 'Organizations',
        path: '/resources/organizations'
      },
      {
        id: 2,
        hasCaret: false,
        icon: KubeIcon,
        label: 'Clusters',
        path: '/resources/clusters'
      }, {
        id: 3,
        hasCaret: false,
        icon: Server,
        label: 'Nodepools',
        path: '/resources/nodepools'
      }, {
        id: 4,
        hasCaret: false,
        icon: CogsIcon,
        label: 'Workloads',
        path: '/resources/workloads'
      }
    ]
  }
]

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_NAVIGATION: {
      return state.map(treeNode =>
        (treeNode.id === action.node)
          ? { ...treeNode, isExpanded: !treeNode.isExpanded }
          : treeNode)
    }
    default: {
      return state
    }
  }
}
