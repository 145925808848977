
export {
  Uppercase
}

/**
 * @return {string}
 */
function Uppercase (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
