import MainPage from '../components/mainPage'
import React from 'react'
import ResourceOverviewPage from '../components/resourceOverview'
import { timeFromNow } from '../util/date'

const clusterColumns = [{
  dataField: 'metadata.name',
  text: 'Name'
}, {
  dataField: 'spec.region',
  text: 'Region'
}, {
  dataField: 'spec.computedProvider',
  text: 'Cloud Provider'
}, {
  dataField: 'spec.kubernetes.version',
  text: 'Kubernetes Version'
}, {
  dataField: 'status.phase',
  text: 'Status'
}, {
  dataField: 'status.heartbeatAge',
  text: 'Last Heartbeat'
}]

const clusterMutator = (clusters) => {
  return clusters.map(cluster => {
    if (cluster.spec) {
      cluster.spec.computedProvider = ''
      if (cluster.spec.cloudProvider && cluster.spec.cloudProvider.kind) {
        cluster.spec.computedProvider = `${cluster.spec.cloudProvider.kind}://${cluster.spec.cloudProvider.account}`
      }
    }
    if (cluster.status) {
      if (cluster.status.conditions && cluster.status.conditions.length > 0) {
        cluster.status.heartbeatAge = timeFromNow(cluster.status.conditions[0].lastHeartbeatTime)
      } else {
        cluster.status.heartbeatAge = ''
      }
    }

    return cluster
  })
}

const ClusterOverview = React.memo(() => {
  return (
    <MainPage>
      <ResourceOverviewPage columns={clusterColumns} resource='clusters' dataMutator={clusterMutator} />
    </MainPage>
  )
})

const nodepoolColumns = [{
  dataField: 'spec.clusterName',
  text: 'Cluster'
}, {
  dataField: 'metadata.computedName',
  text: 'Name'
}, {
  dataField: 'spec.instanceType',
  text: 'Instance Type'
}, {
  dataField: 'status.nodeInfo.containerRuntimeVersion',
  text: 'Container Runtime Version'
}, {
  dataField: 'status.nodeInfo.osImage',
  text: 'Operating System'
}, {
  dataField: 'status.nodes',
  text: 'Nodes'
}, {
  dataField: 'status.phase',
  text: 'Status'
}]

const nodepoolMutator = (nodepools) => {
  return nodepools.map(nodepool => {
    nodepool.metadata.computedName = nodepool.metadata.name.substring(0, nodepool.metadata.name.lastIndexOf('-'))
    return nodepool
  })
}

const NodepoolOverview = React.memo(() => {
  return (
    <MainPage>
      <ResourceOverviewPage columns={nodepoolColumns} resource='nodepools' dataMutator={nodepoolMutator} />
    </MainPage>
  )
})

const workloadColumns = [{
  dataField: 'spec.clusterRef.name',
  text: 'Name'
}, {
  dataField: 'spec.clusterRef.cluster',
  text: 'Cluster'
}, {
  dataField: 'spec.clusterRef.namespace',
  text: 'Namespace'
}, {
  dataField: 'spec.type',
  text: 'Type'
}, {
  dataField: 'spec.versions',
  text: 'Version(s)'
}, {
  dataField: 'status.overallStatus',
  text: 'Status'
}]

const workloadMutator = (workloads) => {
  return workloads.map(workload => {
    if (workload.spec) {
      workload.spec.versions = ''
      if (workload.spec.containers && Array.isArray(workload.spec.containers) && workload.spec.containers.length > 0) {
        if (workload.spec.containers.length === 1) {
          workload.spec.versions = workload.spec.containers[0].version
        } else if (workload.spec.containers.length > 1) {
          workload.spec.containers.forEach((element, index) => {
            if (index > 0) {
              workload.spec.versions += ' / '
            }
            workload.spec.versions += `${element.name}:${element.version}`
          })
        }
      }
    }
    if (workload.status) {
      if (workload.status.conditions && workload.status.conditions[0]) {
        const condition = workload.status.conditions[0]
        if (condition.status === 'True') {
          workload.status.overallStatus = 'Available'
        } else {
          workload.status.overallStatus = 'Unavailable'
        }
      } else {
        workload.status.overallStatus = ''
      }
    }
    return workload
  })
}

const WorkloadOverview = React.memo(() => {
  return (
    <MainPage>
      <ResourceOverviewPage columns={workloadColumns} resource='workloads' dataMutator={workloadMutator} />
    </MainPage>
  )
})

const organizationColumns = [{
  dataField: 'metadata.name',
  text: 'Name'
}, {
  dataField: 'metadata.organization',
  text: 'ID'
}, {
  dataField: 'totalClusters',
  text: 'Total Clusters'
}]

const OrganizationOverview = React.memo(() => {
  return (
    <MainPage>
      <ResourceOverviewPage columns={organizationColumns} resource='organizations' />
    </MainPage>
  )
})

export {
  ClusterOverview,
  NodepoolOverview,
  WorkloadOverview,
  OrganizationOverview
}
