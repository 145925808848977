
export function timeFromNow (date) {
  const now = new Date()
  const time = new Date(date)
  const diff = now - time
  const diffSeconds = diff / 1000
  let timeString = ''
  if (diff < 1500) {
    timeString = 'Now'
  } else if (diffSeconds < 60) {
    timeString = `${diffSeconds.toFixed(0)}s`
  } else if (diffSeconds < 3600) {
    timeString = `${(diffSeconds / 60).toFixed(0)}m`
  } else if (diffSeconds < 3600 * 24) {
    timeString = `${(diffSeconds / 3600).toFixed(0)}h`
  } else {
    timeString = `${(diffSeconds / (3600 * 24)).toFixed(0)}d`
  }
  return timeString
}
