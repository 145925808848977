import { timeFromNow } from '../util/date'
import config from '../config'

export const REQUEST_CLUSTERS = 'requestClusters'
function requestClusters (organization) {
  return {
    type: REQUEST_CLUSTERS,
    organization
  }
}

export const ERROR_CLUSTERS = 'errorClusters'
function errorClusters (organization, error) {
  // Detect registry error and extract message
  let errMsg = error
  if (error && error.kind && error.kind === 'Status') {
    errMsg = error.reason
  }
  return {
    type: ERROR_CLUSTERS,
    error: errMsg,
    organization
  }
}

export const RECEIVE_CLUSTERS = 'receiveClusters'
function receiveClusters (organization, json) {
  return {
    type: RECEIVE_CLUSTERS,
    organization,
    clusters: json.items.map(cluster => {
      if (cluster.spec.cloudProvider && cluster.spec.cloudProvider.kind) {
        cluster.spec.cloudProvider.computedKind = `${cluster.spec.cloudProvider.kind}://${cluster.spec.cloudProvider.account}`
      } else {
        cluster.spec.cloudProvider.computedKind = ''
      }

      if (cluster.status.conditions && cluster.status.conditions[0]) {
        cluster.status.heartbeatAge = timeFromNow(cluster.status.conditions[0].lastHeartbeatTime)
      }
      return cluster
    }),
    receivedAt: Date.now()
  }
}

export function fetchClusters (org) {
  return function (dispatch, getState) {
    dispatch(requestClusters(org))

    const authToken = getState().auth.token
    return fetch(`${config.registryAPI}/v1/organizations/${org}/clusters`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(
      response => {
        if (response.status === 200) {
          response.json().then(json => dispatch(receiveClusters(org, json)))
        } else {
          response.json().then(json => dispatch(errorClusters(org, json)))
        }
      },
      error => {
        console.log('An error occurred.', error)
        dispatch(errorClusters(org, error))
      }
    )
  }
}
