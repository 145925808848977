import React from 'react'
import { Row, Column } from '../components/mainStyles'
import NavBar from '../components/navBar'
import LeftNavigation from '../components/leftNavigation'
import * as PropTypes from 'prop-types'

const MainPage = React.memo((props) => {
  return (
    <Column className='main-page' style={{ flexGrow: '1' }}>
      <NavBar />
      <Row style={{ flexGrow: '1' }}>
        <LeftNavigation />
        <Row className='page-body' style={{ flexGrow: '7', marginTop: '12px' }}>
          {props.children}
        </Row>
      </Row>
    </Column>
  )
})

export default MainPage

MainPage.propTypes = {
  children: PropTypes.node.isRequired
}
