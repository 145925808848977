import React, { Suspense } from 'react'
import { AuthRoute } from './authRoute'
import { OAuthLogin, OAuthCallback2 } from './oauth'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Logout from './logout'
import HomePage from './homePage'
import { ClusterOverview, NodepoolOverview, OrganizationOverview, WorkloadOverview } from './overviewPages'
import config from '../config'
import { Column } from '../components/mainStyles'
import ConfigurationPage from './configurationPage'
const RedocLazy = React.lazy(() => import('redoc').then(module => ({ default: module.RedocStandalone })))
const NotFoundLazy = React.lazy(() => import('./notFound'))

const docOptions = {
  nativeScrollbars: true,
  menuToggle: true,
  theme: { colors: { primary: { main: '#0E5A8A' } } }
}

function Routes () {
  return (
    <Router>
      <Column className='Main' style={{ minHeight: '100vh' }}>
        <Switch>
          <Redirect exact from='/' to='/resources/clusters' />
          <Route path='/logout'>
            <Logout />
          </Route>
          <Route path='/oauth2/login'>
            <OAuthLogin />
          </Route>
          <Route path='/oauth2/callback'>
            <OAuthCallback2 />
          </Route>
          <Route path='/home'>
            <HomePage />
          </Route>
          <AuthRoute path='/resources/clusters'>
            <ClusterOverview />
          </AuthRoute>
          <AuthRoute path='/resources/nodepools'>
            <NodepoolOverview />
          </AuthRoute>
          <AuthRoute path='/resources/organizations'>
            <OrganizationOverview />
          </AuthRoute>
          <AuthRoute path='/resources/workloads'>
            <WorkloadOverview />
          </AuthRoute>
          <AuthRoute path='/configuration/:tab'>
            <ConfigurationPage />
          </AuthRoute>
          <AuthRoute path='/ui/docs'>
            <Suspense fallback={null}>
              <RedocLazy specUrl={`${config.registryAPI}/openapi.json`} options={docOptions} />
            </Suspense>
          </AuthRoute>
          <Route path='/'>
            <Suspense fallback={null}>
              <NotFoundLazy />
            </Suspense>
          </Route>
        </Switch>
      </Column>
    </Router>
  )
}

export default Routes
