const CONFIG = {
  debug: false,
  registryAPI: 'https://registry.darkside.ea.com/api',
  oauth: {
    authorizeEndpoint: 'https://auth.darkside.ea.com/oauth2/authorize',
    tokenEndpoint: 'https://auth.darkside.ea.com/oauth2/token',
    clientID: '7dhajq0g5u2e54gnrhbouq4ul9',
    clientSecret: '',
    usernameClaim: 'email'
  },
  localStorage: {
    oauthRedirectPage: 'OAuthRedirectPage',
    oauthRefreshToken: 'OAuthRefreshToken',
    oauthIDToken: 'OAuthIDToken',
    currentOrganization: 'CurrentOrganization',
    stateUuid: 'StateUuid'
  }
}

function getEnv (key) {
  const safeKey = `REACT_APP_${key}`
  return key.length ? window._env[safeKey] : window._env
}

const envRegistry = getEnv('REGISTRY_API')
if (envRegistry) {
  CONFIG.registryAPI = envRegistry
}

if (process.env.NODE_ENV !== 'production') {
  CONFIG.debug = true
}

export default CONFIG
