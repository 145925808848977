import {
  REQUEST_PERMISSIONS,
  RECEIVE_PERMISSIONS
} from '../actions/permissions'

const initialState = {
  isFetching: false,
  error: null,
  didInvalidate: false,
  readAll: false,
  writeAll: false
}

export default function permissions (state = initialState, action) {
  switch (action.type) {
    case REQUEST_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        error: null
      })
    case RECEIVE_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        error: null,
        readAll: action.readAll,
        writeAll: action.writeAll
      })
    default:
      return state
  }
}
