import React, { useEffect } from 'react'
import config from '../config'
import { Redirect } from 'react-router-dom'
import ErrorPage from './errorPage'
import { authorize, uuidv4 } from '../util/oauth'
import { useSelector, useDispatch } from 'react-redux'
import { authCallback, authError } from '../actions/auth'
import LoadingSpinner from '../components/loadingSpinner'

function OAuthLogin () {
  const stateUuid = uuidv4()
  localStorage.setItem(config.localStorage.stateUuid, stateUuid)
  authorize('openid', stateUuid)
  return <div />
}

function OAuthCallback2 () {
  const authStore = useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    const stateUUID = localStorage.getItem(config.localStorage.stateUuid)
    localStorage.clear(config.localStorage.stateUuid)
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const receivedState = urlParams.get('state')
    if (stateUUID !== null && stateUUID !== receivedState && receivedState !== null) {
      console.error(`States do not match possible cross site attack received ${receivedState} expected ${stateUUID}`)
      dispatch(authError('States do not match possible cross site attack'))
    } else {
      dispatch(authCallback('authorization_code', code))
    }
  }, [dispatch])

  if (authStore.isAuthenticated && !authStore.error) {
    let redirectPage = localStorage.getItem(config.localStorage.oauthRedirectPage)
    localStorage.removeItem(config.localStorage.oauthRedirectPage)
    if (!redirectPage) {
      redirectPage = '/home'
    }
    if (config.debug) console.log('Redirecting to', redirectPage)
    return <Redirect to={{ pathname: redirectPage }} />
  }

  if (!authStore.error) {
    return (
      <LoadingSpinner text='Logging into Registry' />
    )
  } else {
    return ErrorPage(`Unauthenticated ${authStore.error}`)
  }
}

export {
  OAuthLogin,
  OAuthCallback2
}
