import config from '../config'

export const REQUEST_RESOURCE = 'requestResourcePage'
function requestResource (resourceType, org, nextElements, items, total) {
  return {
    type: REQUEST_RESOURCE,
    resourceType,
    org,
    nextElements,
    items,
    total
  }
}

export const ERROR_RESOURCE = 'errorResourcePage'
function errorResource (error) {
  // Detect registry error and extract message
  let errMsg = error
  if (error && error.kind && error.kind === 'Status') {
    errMsg = error.reason
  }
  return {
    type: ERROR_RESOURCE,
    error: errMsg
  }
}

export const RECEIVE_RESOURCE = 'receiveResourcePage'
function receiveResource (json) {
  return {
    type: RECEIVE_RESOURCE,
    items: json.items,
    continueToken: json.metadata.continue,
    total: json.metadata.total
  }
}
export function fetchResource (resourceType, org, nextElements) {
  return function (dispatch, getState) {
    if (resourceType === getState().resourcePagination.resourceType && org === getState().resourcePagination.org) {
      const currentElements = getState().resourcePagination.items.length
      if (currentElements && currentElements > nextElements) {
        return
      }
    }

    dispatch(requestResource(resourceType, org, nextElements, getState().resourcePagination.items, getState().resourcePagination.total))
    const authToken = getState().auth.token

    const continueToken = getState().resourcePagination.continueToken
    let queryParams = ''
    if (continueToken) {
      queryParams = `?continue=${continueToken}`
    }

    fetch(`${config.registryAPI}/v1/organizations/${org}/${resourceType}${queryParams}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(
      response => {
        if (response.status === 200) {
          response.json().then(json => {
            if (getState().resourcePagination.items.length === 0) {
              json.metadata.total = json.items.length
              if (json.metadata.remainingItemCount) {
                json.metadata.total = json.metadata.remainingItemCount + json.metadata.total
              }
              dispatch(receiveResource(json))
            } else {
              // concat performance is terrible look at using push if becomes an issue
              json.items = getState().resourcePagination.items.concat(json.items)
              json.metadata.total = getState().resourcePagination.total
              dispatch(receiveResource(json))
            }
          })
        } else {
          response.json().then(json => dispatch(errorResource(org, json)))
        }
      },
      error => {
        console.log('An error occurred.', error)
        dispatch(errorResource(org, error))
      })
  }
}
export function fetchOrganizations (resourceType, org, nextElements) {
  return function (dispatch, getState) {
    if (resourceType === getState().resourcePagination.resourceType) {
      const currentElements = getState().resourcePagination.items.length
      if (currentElements && currentElements > nextElements) {
        return
      }
    }

    dispatch(requestResource(resourceType, org, nextElements, getState().resourcePagination.items, getState().resourcePagination.total))
    const authToken = getState().auth.token

    fetch(`${config.registryAPI}/v1/organizations`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(
      response => {
        if (response.status === 200) {
          response.json().then(async json => {
            const promises = json.items.map(async org => {
              var resp = await fetch(`${config.registryAPI}/v1/organizations/${org.metadata.organization}/clusters?limit=1`, {
                headers: {
                  Authorization: `Bearer ${authToken}`
                }
              })

              await resp.json().then((cjson) => {
                org.totalClusters = (cjson.metadata.remainingItemCount ? cjson.metadata.remainingItemCount : 0) + cjson.items.length
              })
              return org
            })
            json.items = await Promise.all(promises)
            if (getState().resourcePagination.items.length === 0) {
              json.metadata.total = json.items.length
              if (json.metadata.remainingItemCount) {
                json.metadata.total = json.metadata.remainingItemCount + json.metadata.total
              }
              dispatch(receiveResource(json))
            } else {
              // concat performance is terrible look at using push if becomes an issue
              json.items = getState().resourcePagination.items.concat(json.items)
              json.metadata.total = getState().resourcePagination.total
              dispatch(receiveResource(json))
            }
          })
        } else {
          response.json().then(json => dispatch(errorResource(org, json)))
        }
      },
      error => {
        console.log('An error occurred.', error)
        dispatch(errorResource(org, error))
      })
  }
}
