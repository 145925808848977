import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import config from '../config'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers'

// This file created from https://redux.js.org/recipes/configuring-your-store

const loggerMiddleware = store => next => action => {
  if (config.debug) console.group(action.type)
  if (config.debug) console.info('dispatching', action)
  const result = next(action)
  if (config.debug) console.log('next state', store.getState())
  if (config.debug) console.groupEnd()
  return result
}

const round = number => Math.round(number * 100) / 100

const monitorReducerEnhancer = createStore => (
  reducer,
  initialState,
  enhancer
) => {
  const monitoredReducer = (state, action) => {
    const start = performance.now()
    const newState = reducer(state, action)
    const end = performance.now()
    const diff = round(end - start)

    if (config.debug) console.log('reducer process time:', diff)

    return newState
  }

  return createStore(monitoredReducer, initialState, enhancer)
}

export default function configureStore (preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducerEnhancer]

  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
